import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/adtopografica-site/adtopografica-site/src/components/LayoutMdx.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`LAVORI TOPOGRAFICI`}</h3>
    <h4>{`PIANI QUOTATI`}</h4>
    <p>{`  Rilievi strumentali di zone di terreno al fine di ottenere un grafico con curve di livello per poi poter effettuare analisi di sterri e riporti di terreno.`}</p>
    <h4>{`TRACCIAMENTI`}</h4>
    <p>{`  Rilievo strumentale dei luoghi, successiva elaborazione grafica con l’apposizione dei termini per individuare le linee di confine dei luoghi, strade, zone di scavo o zone in cui verrà inserito e costruito un fabbricato, ecc.`}</p>
    <h4>{`MONITORAGGIO CAVE O FRANE`}</h4>
    <p>{`  Rilievi strumentali di zone di terreno soggette a movimenti franosi o a successiva drastica modificazione dei luoghi. Tale sistema consente, tramite un rilievo di un piano quotato e con l’inserimento di punti fissi, con ripetuti rilievi effettuati nei medesimi punti, un controllo sui volumi e punti modificati della zona.`}</p>
    <h4>{`RICONFINAZIONI`}</h4>
    <p>{`  Rilievi strumentali dei luoghi, al fine di rintracciare i termini di confine o di ricostruzione delle linee di mappa catastali che hanno dato origine alle particelle. Tale rilievo permette quindi di verificare l’esatto confine di una proprietà.`}</p>
    <h4>{`LIVELLAZIONI`}</h4>
    <p>{`  Rilievi strumentali di zone di terreno al fine di verificare poi, con l’utilizzo di software, l’eventuale lavorazione con sterri e riporti di terreno per livellare i vari lotti di terreno al fine di predisporre nuove strade o l’inserimento di nuovi fabbricati.`}</p>
    <h4>{`CALCOLO DEI VOLUMI DI SCAVO`}</h4>
    <p>{`  Rilievi strumentali dei luoghi, prima e dopo le operazioni di scavo, al fine di calcolare l’effettiva volumetria di scavo effettuato.`}</p>
    <h4>{`TRACCIATI DI STRADE`}</h4>
    <p>{`  Rilievi strumentali di zone di terreno al fine del successivo tracciato (picchettamento) per nuove strade.`}</p>
    <h4>{`PROFILI ALTIMETRICI`}</h4>
    <p>{`  Rappresentazione della sezione di un certo percorso che permette di evidenziare le differenze altimetriche lungo il tragitto.`}</p>
    <hr></hr>
    <h3>{`LAVORI CATASTALI`}</h3>
    <h4>{`FRAZIONAMENTI`}</h4>
    <p>{`  Rilievo strumentale dei luoghi e successiva restituzione grafica mediante software al fine di
predisporre tutta la documentazione necessaria per la divisione di una o più particelle di
terreno, con presentazione ed approvazione all’Agenzia delle Entrate mediante pratica Pregeo.`}</p>
    <h4>{`TIPI MAPPALE`}</h4>
    <p>{`  Rilievo strumentale dei luoghi e successiva restituzione grafica mediante software al fine di
predisporre tutta la documentazione necessaria per l’inserimento di un fabbricato ed
ampliamenti con presentazione ed approvazione all’Agenzia delle Entrate mediante pratica
Pregeo.`}</p>
    <h4>{`ACCATASTAMENTO E VARIAZIONE CATASTALE`}</h4>
    <p>{`  Rilievi strumentali di unità immobiliare di nuova costruzione o ristrutturazione/ampliamento e
successiva restituzione grafica mediante software al fine di predisporre tutta la documentazione
necessaria per il classamento dell’unità con presentazione ed approvazione all’Agenzia delle
Entrate mediante pratica Docfa.`}</p>
    <h4>{`VISURE CATASTALI`}</h4>
    <p>{`  Richiesta all’Agenzia delle Entrate del documento contenente tutti i principali dati relativi ad un
immobile (sezione urbana, particella, subalterno, indirizzo, categoria catastale, rendita e nome
del proprietario).`}</p>
    <h4>{`RETTIFICA DATI CATASTALI`}</h4>
    <p>{`  Istanza da presentare all’Agenzia delle Entrate In caso di errori nelle informazioni catastali
(intestatari,indirizzo, numero civico, numero dei vani, superfici ) procedendo alla correzione
degli stessi.`}</p>
    <h4>{`VOLTURE CATASTALI`}</h4>
    <p>{`Documento da presentare all’Agenzia delle Entrate in caso di cambio di proprietario per
aggiornare gli archivi catastali relativi al proprio immobile, questo si rende necessario in caso di
compravendita, successione, ecc. La voltura va presentata entro 30 giorni dall'avvenuto
trasferimento del bene, oltre questo termine si incorre in una sanzione.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      